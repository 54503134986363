import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import FavoriteJokes from './components/FavoriteJokes'
import Board from './components/Board'
import Registro from "./components/Registro";
import WriteJoke from './components/WriteJoke'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Favorites",
    element: <FavoriteJokes />
  },
  {
    path: "/Board",
    element: <Board />
  },
  {
    path: "/WriteJoke",
    element: <WriteJoke />
  },
  {
  path: "/Registro",
  element: <Registro />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
